@import "./../../style/_variables.scss";

.wrapper {
  margin-top: -116px;
}

.heading {
  font-family: 'Play', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130.02%;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #242B30;
  margin-bottom: 30px;
}

.texts {
  max-width: 868px;
  margin-bottom: 55px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #373F45;
    margin-bottom: 16px;
  }
}

.tabs {
  display: flex;
  gap: 30px;
  position: relative;
  margin-bottom: 100px;
  padding-bottom: 400px;

  & > div {
    width: 250px;
    padding: 12px 0;
    text-align: center;
    background: #F0F3F4;
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 132.02%;
    letter-spacing: 0.01em;
    color: #373F45;
    cursor: pointer;

    svg {
      display: none;
    }

    &.active {

      background: #62C624;
      color: #FFFFFF;
      cursor: default;

      .tabContentWrapper {
        display: flex;
      }
    }
  }
}

.tabContentWrapper {
  font-family: 'Manrope', sans-serif;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50px;
  padding: 30px 60px 40px 30px;
  background: #F0F3F4;
  justify-content: space-between;
  height: 400px;
  box-sizing: border-box;
  display: none;
  align-items: flex-start;

  img {
    overflow: hidden;
    flex-shrink: 10;
  }
}

.tabContent {
  width: 660px;
  text-align: left;

  p,
  ul {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #373F45;
    margin-bottom: 16px;
  }

  ul {
    padding-left: 23px;
  }

  li {
    margin-bottom: 3px;
  }

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #62C624;
  }
}

@media (max-width: $xl-size) {
  .tabContentWrapper {
    top: 47px;
  }
  .tabs {
    & > div {
      font-size: 18px;
    }
  }

  .tabContent {
    width: 550px;

    p,
    ul {
      font-size: 14px;
    }
  }
}

@media (max-width: $lg-size) {
  .tabs {
    padding-bottom: 0;
    flex-direction: column;

    & > div {
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 1fr min-content;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;
      text-align: left;

      & > p {
        margin-left: 25px;
        display: block;
      }

      svg {
        cursor: pointer;
        margin-right: 25px;
        display: block;
      }
    }

    .tabContentWrapper {
      position: relative;
      grid-column: 1/3;
      top: 12px;
      height: auto;
      gap: 10px;

      .tabContent {
      flex-shrink: 10;
      }
    }
  }
}

@media (max-width: $md-size) {
  .wrapper {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .tabs {
    margin-bottom: 50px;

    .tabContentWrapper {
      flex-direction: column;
      gap: 30px;

      .tabContent {
        width: 100%;
      }

      img {
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: $sm-size) {
  .tabs {
    gap: 20px;
  }
}