@import "./../../../style/_variables.scss";


.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  background: rgba(0, 0, 0, 0.39);
  backdrop-filter: blur(3px);
  overflow: auto;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
}

.modalWrapper {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 250px;
  box-sizing: border-box;

}

.modal {
  background: #fff;
  width: 100%;
  padding: 35px 50px 64px;
  position: relative;
  min-height: 1880px;
}

.heading {
  font-weight: 800;
  font-size: 32px;
  line-height: 1.4em;
  letter-spacing: 0.01em;
  color: #242B30;
  margin-bottom: 25px;
}

.commonInfoWrapper {
  display: flex;
  gap: 30px;
  margin-bottom: 60px;

  & > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    * {
      box-sizing: border-box;
    }
  }
}

.sliderWRapper {
  max-width: 530px;
}

.mainSlideWrapper {
  position: relative;
  margin-bottom: 20px;
  user-select: none;
  display: flex;

  .navigation {
    position: absolute;
    bottom: 0px;
    padding: 0 8px;
    gap: 20px;
    height: 29px;
    display: flex;
    align-items: center;
    background: rgba(55, 63, 69, 0.6);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 132.02%;
    letter-spacing: 0.05em;
    color: #FFFFFF;

    svg {
      cursor: pointer;
    }
  }
}

.mainSlide {
  width: 530px;
  object-fit: scale-down;
  height: 550px;
}

.slideList {
  display: flex;
  justify-content: space-between;

  img {
    width: 170px;
    height: 180px;
    cursor: pointer;

    &.hidden {
      display: none;
    }
  }
}

.mainProp {
  background: rgba(240, 243, 244, 0.4);
  padding: 30px;
  width: 100%;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #242B30;
    margin-bottom: 15px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 0;
    margin-bottom: 25px;
    list-style: none;

    li {
      padding-left: 25px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      position: relative;
      letter-spacing: 0.01em;
      color: #373F45;

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        background: #62C624;
        border-radius: 50%;
        position: absolute;
        top: 7px;
        left: 7px;
      }
    }
  }
}

.line {
  height: 1px;
  opacity: 0.2;
  background-color: #373F45;
  width: 100%;
  margin-bottom: 15px;
}

.price {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #242B30;
  margin-bottom: 8px;

  span {
    color: #3DA100;
    display: block;
    margin-top: 7px;
  }
}

.note {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: rgba(55, 63, 69, 0.7);
}

.req {
  background: linear-gradient(180deg, #242B30 0%, #2C3543 100%);
  padding: 30px 30px 37px;
  flex-grow: 1;

  b {
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    color: #FFFFFF;
    margin-bottom: 10px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 18px;
  }

  input {
    padding: 12px 15px;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
    outline: none;
    max-width: 340px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.01em;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 25px;

    &::placeholder {
      color: rgba(55, 63, 69, 0.6);
    }
  }

  button {
    background: linear-gradient(180deg, #62C624 0%, #3C9F20 100%);
    box-shadow: 3px 8px 25px rgba(106, 206, 45, 0.25);
    border-radius: 5px;
    padding: 10px 71px;
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 151.02%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;

    &:disabled {
      opacity: .7;
      cursor: default;
      pointer-events: none;
    }
  }
}

.tabsWrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, min-content);
  justify-content: space-between;
}

.tab {
  padding: 12px 33px;
  background: #F0F3F4;
  font-family: 'Play', sans-serif;
  font-style: normal;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
  line-height: 132.02%;
  letter-spacing: 0.01em;
  color: #373F45;
  white-space: nowrap;
  max-width: 360px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;

  &.active {
    background: #62C624;
    color: #fff;

    & + div {
      display: block;
    }
  }

  svg {
    display: none;
  }
}

.contentWrapper {
  width: 100%;
  background-color: #F0F3F4;
  padding: 30px 30px 54px;
  grid-column: 1/4;
  box-sizing: border-box;
  grid-row: 2/3;
  display: none;
}

.tabCommonInfo {

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 132.02%;
    letter-spacing: 0.01em;
    margin-bottom: 24px;
    display: inline-block;
    border-bottom: 2px solid #62C624;
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }

  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    display: block;
    color: #373F45;
  }

  ul {
    display: grid;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #242B30;
    list-style: none;
    padding: 0;
    margin-bottom: 20px;

    li {
      position: relative;
      padding-left: 25px;

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        background: #62C624;
        border-radius: 50%;
        position: absolute;
        top: 7px;
        left: 7px;
      }
    }
  }
}

.table {
  background: #FFFFFF;

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid rgba(55, 63, 69, 0.2);

    &:last-child {
      border: none;
    }

    p {
      padding: 15px 15px;
      font-weight: 500;
    }

    b {
      font-weight: 600;
      padding: 15px 30px;
    }
  }
}

.preTableTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 132.02%;
  margin-top: 30px;
  margin-bottom: 30px;
  letter-spacing: 0.01em;
  display: inline-block;
  border-bottom: 2px solid #62C624;

  &:first-child {
    margin-top: 0;
  }
}

.afterTable {
  color: rgba(55, 63, 69, 0.75);
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 133.2%;
  margin: 25px 0;

  sup {
    color: #62C624;
    font-size: 20px;
    font-weight: 700;
  }
}

.sended {
  width: 100%;

  .sendedHeading {
    color: #FFF;
    text-align: center;
    font-size: 22px;
    font-family: Play, sans-serif;
    font-weight: 700;
    padding-top: 10px;
    margin-bottom: 20px;
  }

  .sendedText {
    color: rgba(255, 255, 255, 0.80);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.16px;
  }

  .sendedLine {
    margin-top: 40px;
    margin-bottom: 20px;
    height: 1px;
    width: 100%;
    opacity: 0.2;
    background: #F0F3F4;
  }

  .sendedLink {
    color: #62C624;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5em;
    width: 100%;
    display: block;
    margin-top: 10px;
  }
}

@media (max-width: $xl-size) {
  .modalWrapper {
    padding: 100px 50px;
  }
  .modal {
    padding: 40px 30px 50px;
    box-sizing: border-box;
  }
  .heading {
    font-size: 28px;
  }
  .commonInfoWrapper {
    gap: 15px;
    margin-bottom: 40px;

    & > div {
      max-width: 50%;
    }
  }
  .mainSlide {
    width: 100%;
    height: auto;
  }

  .sliderWRapper {
    overflow: hidden;
  }

  .slideList {
    gap: 10px;


    img {
      overflow: hidden;
      height: auto;
      width: auto;


    }
  }

  .mainProp {
    padding: 20px 15px;

    .title {
      font-size: 18px;
    }

    ul {
      gap: 5px;

      li {
        font-size: 14px;
      }
    }

    .price {
      font-size: 18px;
    }
  }

  .req {
    b {
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 10px;
      display: block;
    }

    p {
      font-size: 14px;
    }
  }

  .tab {
    font-size: 18px;
    padding: 12px 25px;
  }

  .contentWrapper {
    padding: 25px 20px 50px;

    .title {
      margin-bottom: 20px;
      margin-top: 30px;
      font-size: 18px;
    }

    .text {
      font-size: 14px;
    }
  }
  .tabCommonInfo {
    ul {
      li {
        font-size: 14px;
      }
    }
  }

  .preTableTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .table {
    font-size: 14px;
  }

  .afterTable {
    font-size: 16px;
    margin: 20px 0;
  }
  .sended {
    .sendedHeading {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .sendedHeading {
      padding-top: 0;
    }

    .sendedText {
      font-size: 15px;
    }

    .sendedLine {
      margin-top: 30px;
    }

    .sendedLink {
      font-size: 15px;
    }
  }
}

@media (max-width: $lg-size) {
  .modalWrapper {
    padding: 70px 40px;
  }
  .modal {
    padding: 40px 20px 50px;
    box-sizing: border-box;
  }
  .heading {
    font-size: 24px;
  }


  .tab {
    font-size: 13px;
  }
  .sended {
    .sendedHeading {
      font-size: 18px;
      margin-bottom: 10px;
    }
    .sendedText {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .sendedLine {
      margin-top: 30px;
    }

    .sendedLink {
      font-size: 14px;
    }
  }
}

@media (max-width: $md-size) {
  .modalWrapper {
    padding: 50px 20px;
  }
  .modal {
    min-height: auto;
  }
  .closeIcon {
    width: 21px;
    height: 21px;
    top: 12px;
    right: 12px;
  }
  .heading {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .commonInfoWrapper {
    flex-direction: column;
    margin-bottom: 10px;
    gap: 0;

    & > div {
      max-width: 100%;
    }

    .slideList {
      display: none;
    }

    .mainSlide {
      width: 100%;
      max-height: 500px;
    }
  }
  .mainProp {
    padding-bottom: 35px;

    ul {
      margin-bottom: 20px;
    }
  }
  .req {
    padding: 20px 20px 35px;
    text-align: center;

    input,
    button {
      display: inline-block;
      max-width: 350px;
      width: 100%;
    }
  }

  .tabsWrapper {
    display: flex;
    flex-direction: column;
  }

  .tab {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 16px;
    padding: 15px;

    .openTabIcon {
      display: block;
    }

    &.active {
      .openTabIcon {
        display: none;
      }

      .closeTabIcon {
        display: block;
      }
    }
  }
  .table {
    p {
      text-align: right;
    }
  }
}

@media (max-width: $sm-size) {
  .modalWrapper {
    padding: 40px 10px;
  }

  .modal {
    padding: 40px 10px 50px;
    box-sizing: border-box;
  }
}