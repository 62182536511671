@import "./../../style/_variables.scss";

.wrapper {
  padding: 100px 150px 0;
}

.aboutDocsWrapper {

  .docsGroupHeading {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #242B30;
    margin-bottom: 12px;
  }

  .dpfRowWrapper {
    margin-bottom: 36px;

    &:last-child {
      margin-bottom: 0;
    }

    .pdfRow {
      display: flex;
      align-items: flex-start;
      gap: 5px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.01em;
      text-decoration-line: underline;
      color: #373F45;
    }
  }
}

.banner {
  max-height: 412px;
  max-width: 585px;
  width: 100%;
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  gap: 45px;
  margin-bottom: 50px;
}


.heading {
  font-family: 'Play', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 126.02%;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #242B30;
  margin-bottom: 30px;
}

.tabs {
  display: flex;
  gap: 45px;
  margin-bottom: 25px;

  .tab {
    cursor: pointer;
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 132.02%;
    color: rgba(55, 63, 69, 0.85);

    &.active {
      cursor: default;
      color: #62C624;
      border-bottom: 2px solid #62C624;
    }
  }
}

.aboutTextWrapper {
  max-width: 476px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #373F45;
  }
}

.items {
  display: flex;
  justify-content: space-between;
}

.item {
  padding: 20px 25px 25px;
  max-width: 278px;
}

.itemIcon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #F0F3F4;
  object-fit: none;
  display: block;
  margin: 0 auto 25px;
}

.itemTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #373F45;
}

@media (max-width: $xl-size) {
  .wrapper {
    padding-left: 120px;
    padding-right: 120px;
  }

  .infoWrapper {
    overflow: hidden;
    align-items: flex-start;
  }

  .aboutTextWrapper {
    p {
      font-size: 14px;
    }
  }

  .aboutDocsWrapper {
    .dpfRowWrapper {
      .pdfRow {
         a {
           font-size: 14px;
         }
      }
    }
  }
}

@media (max-width: $lg-size) {
  .wrapper {
    padding-left: 80px;
    padding-right: 80px;
  }
  .infoWrapper {
    gap: 25px;
  }

  .aboutTextWrapper {
    gap: 10px;
  }

  .items {
    .item {
      padding: 15px 15px 20px;
      .itemIcon {
        width: 80px;
        height: 80px;
        object-fit: none;
        margin: 0 auto 20px;
        padding: 15px;
      }
      .itemTitle {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: $md-size) {
  .wrapper {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 50px;
  }

  .ellipses {
    background: #fff !important;
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;

    .heading {
      margin-bottom: 20px;
      font-size: 24px;
    }

    .dpfRowWrapper {
      margin-bottom: 20px;
    }

    .aboutTextWrapper {
      max-width: 100%;
    }

    .tabs {
      margin-bottom: 20px;

      .tab {
        font-size: 18px;
      }
    }
  }

  .info {
    order: 2;
  }

  .banner {
    order: 1;
    max-width: 100%;
  }

  .items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .item {
      .itemIcon {
        width: 50px;
        height: 50px;
        object-fit: contain;
        overflow: inherit;
        margin: 0 auto 20px;
        padding: 15px;
      }
    }
  }
}

@media (max-width: $sm-size) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .item {
      padding: 10px;

      .itemIcon {
        width: 36px;
        height: 36px;
        object-fit: contain;
        overflow: inherit;
        margin: 0 auto 20px;
        padding: 15px;
      }
    }
  }
}
