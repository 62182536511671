@import "./../../style/_variables.scss";

.wrapper {
  background-blend-mode: normal, multiply;
  padding-top: 70px;
  padding-bottom: 102px;
}

.infoWrapper {
  display: flex;
  gap: 60px;
  margin-bottom: 70px;
}


.banner {
  flex-shrink: 5;
  overflow: hidden;
}

.textContent {

  h4 {
    margin-top: 55px;
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 140%;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 30px;
    max-width: 491px;

    span {
      color: #62C624;
    }
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #F0F3F4;
    opacity: 0.8;
    max-width: 535px;
    margin-bottom: 16px;
  }

  button {
    padding: 11px 50px;
    background: linear-gradient(180deg, #62C624 0%, #3C9F20 100%);
    box-shadow: 3px 8px 25px rgba(106, 206, 45, 0.25);
    border-radius: 5px;
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 151.02%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 20px;
    outline: none;
    cursor: pointer;
  }
}

.sliderTitle {
  font-family: 'Play', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 130.02%;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 30px;
}

.sliderWrapper {
  width: 100%;
}

.slide {
  display: flex;
  gap: 30px;
  height: 480px;

  div {
    height: 100%;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
}

.slide_mb {
  max-width: 425px;
}

.slide_col {
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: transparent !important;
  max-width: 300px;
}

.slide_sm {

}

.slide_bg {
  max-width: 500px;
}

.slider_navigation {
  display: none;
  padding: 27px 0 60px;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  gap: 20px;
  font-size: 16px;
  align-items: center;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: rgba(240, 243, 244, 0.8);

  img {
    width: 12px;
    height: 20px;
    cursor: pointer;
  }
}

@media (max-width: $xl-size) {
  .infoWrapper {
    gap: 40px;
  }

  .sliderTitle {
    font-size: 28px;
  }

  .textContent {

    h4 {
      font-size: 28px;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    p {
      font-size: 15px;
    }

    button {

    }
  }

  .slide {
    height: 400px;
    gap: 20px;
  }
  .slide_col {
    gap: 20px;
  }
}

@media (max-width: $lg-size) {
  .infoWrapper {
    gap: 20px;
  }

  .banner {
    flex-shrink: 2;
  }

  .sliderTitle {
    font-size: 24px;
  }

  .textContent {

    h4 {
      font-size: 24px;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    p {
      font-size: 14px;
    }
  }

  .slide {
    height: 350px;
  }
}

@media (max-width: $md-size) {
  .wrapper {
    padding-bottom: 0;
  }
  .infoWrapper {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
  }

  .banner {
    flex-shrink: 2;
  }

  .textContent {

    h4 {
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
    }

    button {

    }
  }
  .slide {
    height: 236px;
  }
  .slider_navigation {
    display: flex;
  }

}

@media (max-width: $sm-size) {
  .textContent {
    h4 {
      font-size: 20px;
    }
  }

  .sliderTitle {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .slide_hidden {
    display: none;
  }
}