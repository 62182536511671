@import "./../../style/_variables.scss";

.heading {
  font-family: 'Play', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130.02%;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #242B30;
  margin-bottom: 30px;
}

.text {
  max-width: 820px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #373F45;
  margin-bottom: 1.2em;

  a {
    color: #62C624;
  }
}

.cards {
  margin-bottom: 100px;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 40px;
  column-gap: 30px;
}

.card {
  overflow: hidden;

  .title {
    background: #373F45;
    opacity: 0.9;
    padding: 5px 105px 5px 15px;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    display: inline-flex;
    color: #FFFFFF;
    clip-path: polygon(calc(100% - 40px) 0, 100% 100%, 0 100%, 0 0);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    box-sizing: border-box;

    svg {
      display: none;
    }
  }

  .content {
    padding: 15px 20px 30px;
    border: 1px solid rgba(55, 63, 69, 0.6);
  }

  .downloadTitle {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #62C624;
    text-decoration: none;


    svg {
      flex-shrink: 0;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .images {
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 10px;

    img {
      width: 100%;
    }
  }
}

@media (max-width: $xl-size) {
  .cards {
    grid-template-columns:  repeat( auto-fit, minmax(320px, 1fr));
  }
}

@media (max-width: $lg-size) {
  .heading {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

@media (max-width: $md-size) {
  .text {
    font-size: 14px;
  }

  .cards {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;

    .card {
      .title {
        width: 100%;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 12px;

        svg {
          display: block;
        }
      }

      .content {
        display: none;
        padding: 20px 15px 30px;
      }

      .images {
        margin-bottom: 20px;
      }

      &.open {
        .content {
          display: block;
        }
        .title {
          svg {
            transform: scaleY(-1);
          }
        }
      }
    }
  }

}