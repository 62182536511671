@import "./../../style/_variables.scss";

.footer {
  min-height: 260px;
  padding: 38px 0;
  box-sizing: border-box;
  background: linear-gradient(180deg, #242B30 0%, #2C3543 100%);
}

.logo {
  align-self: flex-start;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.col {

  b {
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 151.02%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #F0F3F4;
    margin-bottom: 20px;
    display: block;
  }

  a, div {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: rgba(240, 243, 244, 0.8);
    text-decoration: none;
  }

  .link {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .item {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    svg {
      padding-top: 2px;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }
}

@media (max-width: $xl-size) {
  .logo {
    width: 120px;
  }
}

@media (max-width: $lg-size) {

  .wrapper {
    flex-direction: column;
    padding-bottom: 50px;
    gap: 30px;
  }
  .logo {
    margin-bottom: 10px;
  }
  
}