@import "./style/_variables.scss";

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
}


[data-role="none"].slick-arrow {
  width: 20px;
  height: 34px;

  &:before {
    content: none;
  }
}

[data-role="none"].slick-prev {
  left: -50px;
  background-image: url('./../public/images/slidePrev.svg') !important;
}

[data-role="none"].slick-next {
  right: -50px;
  background-image: url('./../public/images/slideNext.svg') !important;
}


.container {
  max-width: 1440px;
  padding: 0 120px;
  margin: 0 auto;
}

.slick-list {margin: 0 -5px;}
.slick-slide>div {padding: 0 5px;}

* {
  margin: 0;
}

@media (max-width: $xl-size) {
  .container {
    padding: 0 90px;
  }
}

@media (max-width: $lg-size) {
  .container {
    padding: 0 50px;
  }
  [data-role="none"].slick-arrow {
    width: 15px;
    height: 25px;
    background-size: 100% 100% !important;
  }
  [data-role="none"].slick-prev {
    left: -30px;
  }
  [data-role="none"].slick-next {
    right: -30px;
  }
}

@media (max-width: $md-size) {
  .container {
    padding: 0 30px;
  }
  [data-role="none"].slick-arrow {
    display: none !important;
  }
}

@media (max-width: $sm-size) {
  .container {
    padding: 0 15px;
  }
}

input {
  caret-color: #62C624;

  &:focus {
    border: 1px solid #62C624 !important;
  }
}

.greenButton {
  border: none;
  outline: none;
  cursor: pointer;
}

.greenButton:hover {
  outline: none;
  background: linear-gradient(0deg, #ffffff25 0%, #ffffff40 100%), #62C624 !important;
  box-shadow: 3px 8px 25px 0px rgba(106, 206, 45, 0.25) !important;
}
.greenButton:active {
  border-radius: 5px;
  background: linear-gradient(0deg, #242b3030 0%, #242b3045 100%), #62C624 !important;
  box-shadow: 3px 8px 25px 0px rgba(106, 206, 45, 0.25) !important;
  outline: none;
}
