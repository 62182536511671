@import "./../../style/_variables.scss";

.wrapper {
  padding: 0;
  max-width: 100%;
  position: relative;
}

.image {
  width: 100%;
  height: 645px;
  //max-width: 1440px;
  //margin: 0 auto;
  display: block;
}

.bgWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  clip-path: polygon(0 0, 63% 0, 35% 100%, 0 100%);
}

.bgTop {
  box-sizing: border-box;
  background: linear-gradient(106.26deg, rgba(56, 64, 74, 0.9) 2.51%, rgba(56, 64, 74, 0) 66.95%), linear-gradient(104.42deg, rgba(66, 74, 83, 0.9) 0%, rgba(79, 92, 111, 0.9) 45.64%);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 1px;
  height: 496px;
}

.bgBottom {
  background: linear-gradient(180deg, #242B30 0%, #2C3543 100%);
  left: 0;
  right: 0;
  height: 304px;
}

.topContent {

  h1 {
    max-width: 800px;
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 125%;
    margin-top: 90px;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    margin-bottom: 30px;
  }

  .description {
    max-width: 550px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.85);
    margin-bottom: 35px;
  }

  .btn {
    cursor: pointer;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    padding: 13px 45px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 151.02%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-decoration: none;

    &:hover {
      background-color: #FFFFFF73;
    }
    &:active {
      background-color: #FFFFFFE3;
      color: #373F45;
      path {
        fill: #373F45;
      }
    }
  }
}

.bottomContent {

  .bottomHeading {
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.9);
    padding-top: 50px;
    margin-bottom: 20px;
  }

  .phone {
    padding: 15px;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    box-sizing: border-box;
    line-height: 140%;
    letter-spacing: 0.01em;
    max-width: 340px;
    width: 100%;
    outline: none;
    margin-bottom: 30px;
  }

  .btn {
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 151.02%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 13px 55px;
    background: linear-gradient(180deg, #62C624 0%, #3C9F20 100%);
    box-shadow: 3px 8px 25px rgba(106, 206, 45, 0.25);
    border-radius: 5px;
    display: flex;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;

    &.disabled {
      opacity: .7;
      pointer-events: none;
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 65px;
  height: 152px;
  padding-top: 30px;
  box-sizing: border-box;

  .item {
    display: flex;
    gap: 15px;
    width: 340px;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #242B30;
    margin-bottom: 10px;
  }

  .imageWrapper {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #F0F3F4;
  }

  .text {
    width: 240px;
    display: flex;
    flex-direction: column;

    a {
      color: #62C624;
      white-space: nowrap;
      text-decoration: none;
      font-weight: 600;
    }
  }
}

.sended {
  width: 37%;
}

.sendedHeading {
  color: #FFF;
  text-align: left;
  font-size: 20px;
  font-family: Play, sans-serif;
  font-weight: 700;
  padding-top: 50px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sendedText {
  color: rgba(255, 255, 255, 0.80);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.16px;
  text-align: left;
  max-width: 300px;
  margin-bottom: 30px;
}
.sendedLine {
  margin-top: 40px;
  margin-bottom: 20px;
  height: 1px;
  width: 100%;
  opacity: 0.2;
  background: #F0F3F4;
}

.sendedButton {
  height: 50px;
  width: 270px;
  border-radius: 5px;
  background: var(--button, linear-gradient(180deg, #62C624 0%, #3C9F20 100%));
  box-shadow: 3px 8px 25px 0px rgba(106, 206, 45, 0.25);
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-family: Play, sans-serif;
  font-weight: 700;
  line-height: 151.023%;
  letter-spacing: 0.32px;
  text-transform: uppercase;
}

@media (max-width: 1440px) {
  .bgWrapper {
    clip-path: polygon(0 0, 63% 0, 40% 100%, 0 100%);
  }

  .footer {
    padding-right: 0;
    gap: 10px;
  }
}

@media (max-width: $xl-size) {
  .image {
    height: 496px;
    padding-bottom: 304px;
  }
  .bgWrapper {
    clip-path: none;
  }
  .footer {
    justify-content: space-between;
  }
  .sended {
    width: 100%;
  }
}

@media (max-width: $lg-size) {
  .topContent {
    h1 {
      font-size: 42px;
      margin-top: 70px;
      margin-bottom: 25px;
    }

    .description {
      font-size: 18px;
      margin-bottom: 35px;
    }
  }

  .bgTop {
    height: 420px;
  }

  .image {
    height: 420px;
  }
}

@media (max-width: $md-size) {
  .topContent {
    h1 {
      font-size: 32px;
      margin-top: 50px;
      margin-bottom: 20px;
      max-width: 600px;
    }

    .description {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }

  .bgTop {
    height: 370px;
  }

  .image {
    height: 370px;
    padding-bottom: 250px;
  }

  .bgBottom {
    height: 250px;
  }

  .bottomContent {

    .bottomHeading {
      font-size: 16px;
      padding-top: 25px;
      margin-bottom: 20px;

    }
  }

  .footer {
    height: auto;

    .item {
      flex-direction: column;
      align-items: center;
      gap: 8px;
      text-align: center;
    }

    .imageWrapper {
      width: 50px;
      height: 50px;
      flex-shrink: 0;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  .sendedHeading {
    padding-top: 20px;
    font-size: 20px;
    margin-bottom: 15px;
  }

  .sendedText {
    font-size: 14px;
  }
  .sendedLine {
    margin-top: 25px;
  }
  .sendedLink {
    font-size: 14px;
  }

}

@media (max-width: $sm-size) {
  .footer {
    .item {
      width: 100%;
    }

    .text {
      overflow: hidden;
      max-width: 35vw;
      font-size: 13px;
    }
  }
}