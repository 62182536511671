@import "./../../style/_variables.scss";

.wrapper {
  padding: 50px 0 74px;
  min-height: 320px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.heading {
  font-family: 'Play', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 130.02%;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #242B30;
  margin-bottom: 32px;

  span {
    position: relative;

    &:before {
      content: '';
      display: block;
      height: 4px;
      background: #62C624;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
}

.forms {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;

  input {
    padding: 15px;
    background: #FFFFFF;
    outline: none;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
    max-width: 340px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.01em;
  }
}

.button {
  font-family: 'Play', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 151.02%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: linear-gradient(180deg, #62C624 0%, #3C9F20 100%);
  box-shadow: 3px 8px 25px rgba(106, 206, 45, 0.25);
  padding: 13px 48px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;

  &:disabled {
    opacity: .7;
    pointer-events: none;
    cursor: none;
  }
}

.button:hover {
  outline: none;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1450980392) 0%, rgba(255, 255, 255, 0.2509803922) 100%), #62C624 !important;
  box-shadow: 3px 8px 25px 0px rgba(106, 206, 45, 0.25) !important;
}

.button:active {
  background: linear-gradient(0deg, rgba(36, 43, 48, 0.1882352941) 0%, rgba(36, 43, 48, 0.2705882353) 100%), #62C624 !important;
  box-shadow: 3px 8px 25px 0px rgba(106, 206, 45, 0.25) !important;
  outline: none;
}

.sended {
  .sendedHeading {
    color: #242B30;
    font-size: 25px;
    font-family: Play, sans-serif;
    font-weight: 700;
    line-height: 130.023%;
    letter-spacing: 0.625px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .sendedText {
    color: #373F45;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.16px;
    margin-bottom: 30px;
  }
  .sendedButton {
    border-radius: 5px;
    background: var(--button, linear-gradient(180deg, #62C624 0%, #3C9F20 100%));
    box-shadow: 3px 8px 25px 0px rgba(106, 206, 45, 0.25);
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-family: Play, sans-serif;
    font-weight: 700;
    line-height: 151.023%;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    width: 250px;
    height: 50px;
  }
}

@media (max-width: $lg-size) {
  .heading {
    font-size: 28px;
    max-width: 430px;
  }
  .sended {
    .sendedHeading {
      font-size: 22px;
      margin-bottom: 10px;
    }

    .sendedText {
      font-size: 15px;
    }
  }
}

@media (max-width: $md-size) {
  .wrapper {
    background: #DFE5E7 !important;
  }
}

@media (max-width: $sm-size) {
  .wrapper {
    padding: 30px 0 40px;
    height: auto;
  }
  .heading {
    max-width: 330px;
    font-size: 20px;
  }
  .forms {
    flex-direction: column;
    gap: 15px;

    input {

    }
  }
}