@import "./../../style/_variables.scss";

@keyframes showHeader {
  from {
    top: -130px;
  }
  to {
    top: 0px;
  }
}

.headerWrapper {
  z-index: 5;
  top: 0;
  padding: 24px 0;
  box-sizing: border-box;
  background-color: #242B30;
  transition-duration: .5s;

  &.unVisible {
    position: sticky;
    top: -130px;
  }

  &.visible {
    position: sticky;
    animation-name: showHeader;
  }
}

.header {
  display: flex;
  position: relative;
  z-index: 2;
  align-items: center;
}

.contentWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation {
  display: flex;
  gap: 50px;
  margin: 0 auto;
}

.navigation a {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.01em;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
}

@media (min-width: $md-size) {
  .navigation {
    a.active {
      color: #62C624;
      border-bottom: 2px solid #62C624;
    }
  }
}

.contactsWrapper {
  display: flex;
  align-items: center;
}

.contactsWrapper img {
  width: 46px;
  height: 45px;
  margin-right: 15px;
}

.logo {
  width: 156px;
  height: 81px;
}

.phoneNumbers {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.addresWrapper {
  display: none;
}

.phoneNumbers a {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.5em;
  text-decoration: none;
  color: #FFFFFF;
}

.burgerMenu {
  display: none;
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 20px;
  margin-left: auto;

  div {
    transition-duration: .2s;
    position: absolute;
    height: 4px;
    width: 30px;
    background: #62C624;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }
}

@media (max-width: $xl-size) {
  .logo {
    width: 130px;
    height: 70px;
  }
  .navigation {
    gap: 30px;
  }

  .contactsWrapper img {
    width: 30px;
    height: 30px;
  }


}

@media (max-width: $lg-size) {
  .logo {
    width: 102px;
    height: 53px;
  }
  .navigation {
    gap: 15px;

    a {
      font-size: 15px;
    }
  }

  .phoneNumbers a {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5em;
    color: #FFFFFF;
  }
}

@media (max-width: $md-size) {
  .headerWrapper {
    padding: 17px 0;
  }

  .burgerMenu {
    display: block;
  }

  .contentWrapper {
    display: none;
    background: #373F45;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    padding: 25px 20px;

    .contactsWrapper {
      display: grid;

      img {
        display: none;
      }

      a {
        font-weight: 600;
        font-size: 16px;
        line-height: 151.02%;
        text-align: left;
        color: #62C624;
        padding-left: 21px;
        background: url('/./../public/images/call.svg') no-repeat left center;
      }

      &::before {
        content: 'Контакты';
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        opacity: 0.8;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }

    .addresWrapper {
      display: block;
      margin-top: 27px;

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        opacity: 0.8;
        margin-bottom: 8px;
      }

      address {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: #F0F3F4;
        opacity: 0.8;
      }
    }
  }

  .open {
    &.unVisible {
      top: 0;
    }

    .contentWrapper {
      box-sizing: border-box;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 70px;

      .navigation {
        display: block;
        border-bottom: 1px solid rgba(240, 243, 244, 0.1);
        margin-bottom: 20px;

        a {
          display: block;
          margin-bottom: 25px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          letter-spacing: 0.01em;
          color: #FFFFFF;
          opacity: 0.8;
          text-decoration: none;

          &:first-child {
            border-bottom: none
          }
        }
      }
    }

    .burgerMenu {

      div {
        &:nth-child(1) {
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
        }

        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }
  }
}