@import "./../../style/_variables.scss";

.wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
}

.heading {
  font-family: 'Play', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 126.02%;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #242B30;
  text-align: center;
  margin-bottom: 50px;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.card {
  height: 740px;
  padding: 0 20px 36px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cardImage {
  max-width: 340px;
  max-height: 330px;
  margin-bottom: 20px;
}

.cardTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #242B30;
  margin-bottom: 10px;
}

.cardList {
  padding-left: 2px;
  margin-bottom: 20px;

  p {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    margin-bottom: 8px;
    color: #373F45;

    &::before {
      content: '';
      min-width: 8px;
      display: inline-block;
      margin-right: 10px;
      border-radius: 50%;
      margin-top: 7px;
      height: 8px;
      background: #62C624;
    }
  }
}

.cardPrice {
  margin-top: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #242B30;
  margin-bottom: 25px;
}

.cardBtn {
  border: none;
  cursor: pointer;
  outline: none;
  background: linear-gradient(180deg, #62C624 0%, #3C9F20 100%);
  box-shadow: 3px 8px 25px rgba(106, 206, 45, 0.25);
  border-radius: 5px;
  font-family: 'Play', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 151.02%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 12px 24px;
  width: 100%;
  color: #FFFFFF;
}

@media (max-width: $xl-size) {
  .cards {
    grid-template-columns: 1fr 1fr;
  }

  .cardImage {
    max-width: 340px;
    max-height: 330px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: $lg-size) {
  .heading {
     margin-bottom: 30px;
  }

  .cards {
    gap: 20px;
  }

  .card {
    height: auto;
  }

  .cardImage {
    display: block;width: 100%;
  }

}

@media (max-width: $md-size) {
  .ellipses {
    background: none !important;
  }
  .heading {
    font-size: 24px;
    line-height: 126.02%;
  }
  .wrapper {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .card {
    padding-left: 15px;
    padding-right: 15px;
  }
  .cardImage {
    margin-bottom: 15px;
  }
  .cardTitle {
    font-size: 16px;
    line-height: 22px;
  }

  .cardList {
    p {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
  .cardPrice {
    font-size: 18px;
  }
}

@media (max-width: $sm-size) {
  .wrapper {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  .heading {
    font-size: 20px;
    line-height: 126.02%;
  }
  .cards {
    grid-template-columns: 1fr;
  }
  .card {
    padding-left: 10px;
    padding-right: 10px;
  }

}