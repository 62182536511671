@import "./../../../style/_variables.scss";

.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.39);
  backdrop-filter: blur(3px);
  display: flex;
  padding: 10px;
}

.modal {
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  border: 15px solid #FFFFFF;
  filter: drop-shadow(0px 20px 40px rgba(36, 36, 36, 0.5));
  max-width: 830px;
  max-height: 730px;
  height: 100%;
  width: 100%;
  margin: auto;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 10;
  background: rgba(55, 63, 69, 0.6);
  width: 36px;
  height: 36px;
  display: flex;

  svg {
    margin: auto;
    width: 21px;
    height: 21px;
  }
}

.imageWrapper {
  overflow: hidden;
  height: 100%;
}

.image {
  display: block;
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}