@import "./../../style/_variables.scss";


.heading {
  font-family: 'Play', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130.02%;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #242B30;
  margin-bottom: 30px;

}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #373F45;
  margin-bottom: 40px;
  max-width: 689px;
}

.cards {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 101px;
}

.card {
  background: rgba(240, 243, 244, 0.4);
  padding: 60px 25px 50px;

  b {
    display: block;
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 132.02%;
    letter-spacing: 0.01em;
    color: #242B30;
    margin-top: 20px;
    margin-bottom: 10px;
    max-width: 320px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #373F45;
    max-width: 320px;
  }
}

@media (max-width: $xl-size) {
  .cards {
    gap: 15px;
  }
  .card {
    padding: 50px 20px 40px;

    b {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }
}

@media (max-width: $lg-size) {
  .heading {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .text {
    font-size: 15px;
  }
}

@media (max-width: $md-size) {
  .cards {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 50px;
  }
}

@media (max-width: $sm-size) {
  .heading {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .text {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .cards {
    gap: 20px;

    .card {
      padding: 35px 15px;
      min-height: 260px;

      b {
        margin-top: 15px;
      }
    }

    img {
      height: 70px
    }
  }
}