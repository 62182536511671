@import "./../../style/_variables.scss";

.wrapper {
  padding-top: 100px;
  padding-bottom: 186px;
}

.heading {
  font-family: 'Play', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130.02%;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #242B30;
  margin-bottom: 40px;
}

.topTextWrapper {
  display: flex;
  gap: 40px;
  margin-bottom: 50px;
  align-items: flex-start;
}

.topBanner {
  flex-shrink: 1;
  overflow: auto;
}

.topText {
  padding-left: 30px;
  max-width: 622px;
  flex-shrink: 3;

  .textWithBorder {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #373F45;
    border-left: 5px solid #62C624;
    padding-left: 25px;
    transform: translateX(-30px);
    margin-bottom: 22px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #373F45;
    margin-bottom: 16px;

    span {
      color: #62C624;
    }
  }
}

.bottom {
  h5 {
    margin-bottom: 25px;
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
    color: #242B30;

  }
}

.bottomTextWrapper {
  display: flex;
  gap: 40px;
}

.bottomText {
  max-width: 776px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #373F45;

  p {
    margin-bottom: 16px;
  }

  ul {
    padding-left: 0;

    li {
      padding-left: 30px;
      margin-bottom: 14px;
      list-style: none;
      background-repeat: no-repeat;
    }
  }
  .bottomBanner {
    display: none;
  }
}

@media (max-width: $xl-size) {
  .wrapper {
    padding-top: 80px;
  }
  .topTextWrapper {
    gap: 30px;
    margin-bottom: 40px;
  }

  .heading {
    font-size: 28px;
  }

  .topText {
    flex-shrink: 5;
    padding-left: 15px;

    .textWithBorder {
      font-size: 18px;
      padding-left: 15px;
      transform: translateX(-20px);

    }

    p {
      font-size: 15px;
    }
  }
  .bottom {
    h5 {
      margin-bottom: 20px;
    }
  }

  .bottomText {
    font-size: 15px;
  }
}

@media (max-width: $lg-size) {

  .wrapper {
    padding-top: 70px;
  }

  .heading {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .topTextWrapper {
    gap: 30px;

    .topText {
      flex-shrink: 2;
      padding-left: 15px;

      .textWithBorder {
        font-size: 16px;
        padding-left: 15px;
      }

      p {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }

  .bottomTextWrapper {
    align-items: flex-start;
  }
  .bottomBanner {
    width: 300px;
  }

  .bottomText {
    font-size: 14px;
  }

}

@media (max-width: $md-size) {
  .wrapper {
    padding-bottom: 50px;
  }
  .topBanner {
    width: 100%;
  }
  .wrapper {
    padding-top: 60px;
  }
  .heading {
    font-size: 22px;
  }
  .wrapper {
    background: none !important;
  }
  .topTextWrapper {
    flex-direction: column;

    .topText {

      .textWithBorder {
        margin-bottom: 15px;
      }
    }
  }
  .bottomBanner {
    display: none;
  }
  .bottom {
    h5 {
      font-size: 20px;
    }
  }
  .bottomText {
    .bottomBanner {
      max-width: 100%;
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: $sm-size) {
  .wrapper {
    padding-top: 50px;
  }
  .heading {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .topTextWrapper {
    flex-direction: column;

    .topText {

      .textWithBorder {
        margin-bottom: 15px;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}